var dateFormat = require('dateformat');

export default {

  downloadIcs(boxOffice, event, eventTime) {
    var content = "BEGIN:VCALENDAR\n" + 
                    "VERSION:2.0\n" +
                    "BEGIN:VEVENT\n"
    if (event.location) {
      content += "LOCATION:" + event.location + "\n";
    }
    content += "ORGANIZER;CN=" + event.name
    if (event.email) {
      content += ":MAILTO:" + event.email;
    }
    content += "\n";
    if (eventTime.start) {
      var startDate = dateFormat(new Date(eventTime.start), "yyyymmdd'T'HHMMss'Z'");
      content += "DTSTART:" + startDate + "\n";
    }
    if (eventTime.end) {
      var endDate = dateFormat(new Date(eventTime.end), "yyyymmdd'T'HHMMss'Z'");
      content +="DTEND:" + endDate + "\n";
    }
    content += "SUMMARY:" + event.name + "\n";
    content += "DESCRIPTION:";
    if (event.summary) {
      content += event.summary + "\\n\\n" 
    } 
    content += this.makeURL(boxOffice, event, eventTime) + "\n";
    content += "END:VEVENT\n" +
               "END:VCALENDAR\n";

    var blob = new Blob([content], { type: 'text/ics;charset=utf-8;' });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'filename.ics');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  googleCalendar(boxOffice, event, eventTime) {
		var link = "https://www.google.com/calendar/render?action=TEMPLATE&text=";
		link += encodeURI(event.name);
    link += "&details=";
    if (event.summary) {
      link += encodeURI(event.summary + "\n\n");
    }
  	link += encodeURI(this.makeURL(boxOffice, event, eventTime));
    if (event.location) {
      link += "&location=";
      link += encodeURI(event.location);
    }
    link += "&dates=";
    var start = eventTime.start;
    var end = eventTime.end;
    if (!end) {
      end = start;
    }
    if (!start) {
      start = end;
    }
    if (start) {
      var startDate = dateFormat(new Date(start), "yyyymmdd'T'HHMMss'Z'");
      link += startDate;
    }
		link += "/";
    if (end) {
      var endDate = dateFormat(new Date(end), "yyyymmdd'T'HHMMss'Z'");
      link += endDate;
    }
    location.href = link;
  },

  makeURL(boxOffice, event, eventTime) {
    var url = window.location.origin + "/boxOffice/" + boxOffice.path + "/" + event.path;
    if (eventTime) {
      url += "/" + eventTime.id;
    }
    return url;
  },

  googleCalendarLink(boxOffice, event, eventTime) {
    window.open(this.makeURL(boxOffice, event, eventTime) + "/calendar.google", '_blank');
  },

  icsCalendarLink(boxOffice, event, eventTime) {
    this.downloadIcs(boxOffice, event, eventTime);
  },

}